import { Button, ButtonGroup, Description, Heading, Panel } from '@repo/ui'
import { RadioButtonGroup } from '@repo/ui/client'
import { observer } from 'mobx-react-lite'
import { useCallback, useEffect, useState } from 'react'
import Balancer from 'react-wrap-balancer'

import { BetaBadge } from '@/components/BetaBadge'
import { FormError } from '@/components/forms/FormError'
import { PageTitle } from '@/components/PageTitle'
import { WarningBadge } from '@/components/WarningBadge'
import { DeviceSelector } from '@/store/integrations/spotify/components/DeviceSelector'
import { SpotifyAuthButton } from '@/store/integrations/spotify/components/SpotifyAuthButton'
import { useQuerySpotifyDevices } from '@/store/integrations/spotify/queries/useQuerySpotifyDevices'
import { useSpotify } from '@/store/integrations/spotify/useSpotify'
import StoreService from '@/store/StoreService'
import { ErrorWithMessage, toErrorWithMessage } from '@/utils/get-error-message'

interface PlaybackSettingsProps {
  buttonText?: string
  onError?: (error: ErrorWithMessage) => void
  onSubmit?: () => void
}

export const PlaybackSettings = observer(function PlaybackSettings({
  buttonText = 'Save',
  onError,
  onSubmit,
}: PlaybackSettingsProps) {
  const { currentGame } = StoreService.getStore()
  const { auth, deviceService, devices } = useSpotify()
  const { isAuthenticated } = auth

  const {
    data: devicesList = [],
    isFetching,
    isLoading: isLoadingDevices,
  } = useQuerySpotifyDevices()

  const isLoading = isLoadingDevices || isFetching

  const externalDevices = devicesList.filter((device) => device.isExternal)

  const [enableExternal, setEnableExternal] = useState(
    devices.enableExternalDevices
  )

  const getInitialSelectedDevice = useCallback(() => {
    if (devices.activeDevice?.isExternal) {
      return devices.activeDevice
    }

    if (externalDevices.length) {
      return externalDevices[0]
    }
  }, [devices.activeDevice, externalDevices])

  const initialSelectedDevice = getInitialSelectedDevice()
  const [selectedDevice, setSelectedDevice] = useState(initialSelectedDevice)

  const handleSubmit = async () => {
    try {
      if (!enableExternal) {
        await deviceService.enableInternalDevice()
        await deviceService.activateInternalDevice()
      } else if (selectedDevice) {
        await deviceService.activateExternalDevice(selectedDevice.id)
      }

      devices.setEnableExternalDevices(enableExternal)

      if (selectedDevice && selectedDevice !== devices.activeDevice) {
        currentGame?.tracker.changePlaybackDevice(selectedDevice)
      }

      onSubmit?.()
    } catch (error) {
      console.error(error)
      onError?.(toErrorWithMessage(error))
    }
  }

  const canSubmit = () => {
    if (enableExternal) {
      if (!selectedDevice) return false

      return selectedDevice.isExternal
    }

    return true
  }

  const handleChange = (id: string) => {
    const selectedDevice = externalDevices.find((device) => device.id === id)
    setSelectedDevice(selectedDevice)
  }

  const handleClickRefresh = async () => {
    await deviceService.queryDevices()

    if (selectedDevice) {
      const updatedSelectedDevice = externalDevices.find(
        (device) => device.id === selectedDevice?.id
      )
      setSelectedDevice(updatedSelectedDevice)
    } else if (externalDevices.length) {
      setSelectedDevice(externalDevices[0])
    }

    currentGame?.tracker.spotifyDeviceRefreshClicked()
  }

  useEffect(() => {
    setEnableExternal(devices.enableExternalDevices)
  }, [devices.enableExternalDevices])

  return (
    <>
      {isAuthenticated && (
        <>
          <PageTitle>Choose a playback source</PageTitle>

          <div className='mb-8'>
            <RadioButtonGroup
              direction='vertical'
              options={[
                {
                  id: 'external-playback',
                  label: (
                    <div className='flex w-full flex-1 flex-col gap-2'>
                      <Heading className='w-full'>
                        <Balancer>Classic Player</Balancer>
                      </Heading>
                      <Description>
                        <Balancer>
                          Control a Spotify app outside of Rockstar Bingo
                        </Balancer>
                      </Description>
                    </div>
                  ),
                  name: 'playback-type-selection',
                  value: 'true',
                },
                {
                  id: 'internal-playback',
                  label: (
                    <div className='flex w-full flex-1 flex-col gap-2 overflow-hidden'>
                      <Heading className='w-full flex-col gap-2 xs:flex-row'>
                        <Balancer>Rockstar Bingo Player</Balancer>
                        <BetaBadge />
                      </Heading>
                      <Description>
                        <Balancer>
                          Play Spotify audio directly from Rockstar Bingo via
                          our web player
                        </Balancer>
                      </Description>
                    </div>
                  ),
                  name: 'playback-type-selection',
                  value: 'false',
                },
              ]}
              value={enableExternal.toString()}
              onChange={() => {
                setEnableExternal(!enableExternal)
              }}
            />
          </div>

          {!enableExternal && devices.isInternalPlayerInAnotherTab && (
            <>
              <Panel className='flex flex-col gap-4'>
                <WarningBadge />

                <Description>
                  You have already activated the Rockstar Bingo Player in
                  another tab.
                </Description>
              </Panel>

              <ButtonGroup className='mt-4' direction='column'>
                <Button
                  isLoading={deviceService.isActivatingDevice}
                  text='Play Here Instead'
                  onClick={handleSubmit}
                />

                <Button
                  text='Cancel'
                  variant='outlined'
                  onClick={() => onSubmit?.()}
                />
              </ButtonGroup>
            </>
          )}

          {!enableExternal && !devices.isInternalPlayerInAnotherTab && (
            <>
              <Heading>Rockstar Bingo Player</Heading>
              <Description className='mt-2'>
                Play audio from your Spotify account directly from Rockstar
                Bingo. Unlike the Classic Player, this option does not require a
                separate Spotify app to be open. The audio will play from your
                web browser.
              </Description>

              <Button
                className='mt-8'
                disabled={!canSubmit()}
                isLoading={deviceService.isActivatingDevice}
                text={buttonText}
                onClick={handleSubmit}
              />
            </>
          )}

          {enableExternal && (
            <>
              <DeviceSelector
                devices={externalDevices}
                isLoading={isLoading}
                selectedDevice={selectedDevice}
                onChange={handleChange}
                onClickRefresh={() => void handleClickRefresh()}
              />

              <Button
                className='mt-2'
                disabled={!canSubmit()}
                isLoading={deviceService.isActivatingDevice}
                text={buttonText}
                onClick={handleSubmit}
              />
            </>
          )}

          {!canSubmit() && !isLoading && (
            <div className='mt-6'>
              <FormError error='Please select a device to continue' />
            </div>
          )}
        </>
      )}

      {!isAuthenticated && (
        <p className='mb-6 text-center'>
          Connect a Spotify Premium account to use playback.
        </p>
      )}

      <div className='my-8'>
        <SpotifyAuthButton showDisconnect />
      </div>
    </>
  )
})

import { Player } from '@repo/types'
import { sleep } from '@repo/ui'
import * as Sentry from '@sentry/react'
import { observer } from 'mobx-react-lite'
import React, { createContext, useCallback, useState } from 'react'

import { apiClient } from '@/api/api-client'
import { ManualBingoVerificationModal } from '@/components/ManualVerificationModal'
import { useDialogContext } from '@/dialogs/DialogContext'
import { useIsMounted } from '@/hooks/useIsMounted'
import { useSpotify } from '@/store/integrations/spotify/useSpotify'
import { getErrorMessage } from '@/utils/get-error-message'

import { useGameContext } from './game-context'

interface GameControlsContext {
  handleAdmitAllPlayersClick: (playerCount: number) => Promise<void>
  handleAdmitPlayerClick: (playerId: string) => Promise<void>
  handleFinishGameClick: () => void
  handleReadmitPlayerClick: (playerId: string) => void
  handleRemovePlayerClick: (playerId: string) => Promise<void>
  handleStartGameClick: () => Promise<void>
  handleVerifyBingoClick: (playerId: string) => Promise<void>
  isFinishingGame: boolean
  isStartingGame: boolean
  isVerifying: boolean
}

const GameControlsContext = createContext({} as GameControlsContext)

export function useGameControlsContext(): GameControlsContext {
  const context = React.useContext(GameControlsContext)
  if (context === undefined) {
    throw new Error('useGameControlsContext must be used within a GameControlsContextProvider')
  }
  return context
}

interface GameControlsContextProviderProps {
  children: React.ReactElement
}

export const GameControlsContextProvider = observer(function GameControlsContextProvider({
  children
}: GameControlsContextProviderProps) {
  const spotify = useSpotify()
  const isMounted = useIsMounted()
  const [isStartingGame, setIsStartingGame] = useState(false)
  const [isVerifying, setIsVerifying] = useState(false)
  const [isFinishingGame, setIsFinishingGame] = useState(false)

  const { gameData, gameId, tracker } = useGameContext()

  const { setConfirmPrompt, setErrorDialog } = useDialogContext()

  const [showManualBingoVerificationModal, setShowManualBingoVerificationModal] = useState(false)

  const [playerId, setPlayerId] = useState('')

  const handleStartGameClick = async () => {
    try {
      setIsStartingGame(true)

      await apiClient.startGame(gameId)
      tracker.startGame()
    } catch (error) {
      setErrorDialog({ error: getErrorMessage(error) })
      Sentry.captureException(error)
    } finally {
      setIsStartingGame(false)
    }
  }

  const finishGame = useCallback(async () => {
    try {
      setIsFinishingGame(true)

      if (gameData.type === 'music' && spotify.player.isPlaying) {
        await sleep(500)

        setConfirmPrompt({
          description: 'You can stop playback now or let it continue while you set up another game',
          heading: 'Would you like to stop the music?',
          onConfirm: () => {
            void spotify.playback.pause()
          }
        })
      }

      await apiClient.finishGame(gameId)
      tracker.finishGame()
    } catch (error) {
      setErrorDialog({ error: getErrorMessage(error) })
      Sentry.captureException(error)
    } finally {
      if (isMounted.current) {
        setIsFinishingGame(false)
      }
    }
  }, [
    tracker,
    gameId,
    gameData.type,
    spotify.playback,
    spotify.player.isPlaying,
    isMounted,
    setConfirmPrompt,
    setErrorDialog
  ])

  const handleFinishGameClick = useCallback(() => {
    setConfirmPrompt({
      description: 'This will end the current game for all players and display a game summary',
      heading: 'Are you sure you want to finish the game?',
      onConfirm: () => {
        void finishGame()
      }
    })
  }, [setConfirmPrompt, finishGame])

  const handleVerifyBingoClick = useCallback(
    async (playerId: string) => {
      if (gameData.disableSystemBingoVerification) {
        setIsVerifying(true)
        setPlayerId(playerId)
        setShowManualBingoVerificationModal(true)
      } else {
        setIsVerifying(true)
        const { bingoConfirmed } = await apiClient.verifyBingo(gameId, playerId)
        tracker.verifyBingo(bingoConfirmed)
        setIsVerifying(false)
      }
    },
    [gameData, gameId, tracker]
  )

  const handleConfirmBingoClick = useCallback(
    async (playerData: Player) => {
      setPlayerId('')
      setShowManualBingoVerificationModal(false)

      await apiClient.confirmBingo(gameId, playerData)
      tracker.confirmBingo(playerData, true, true)
      setIsVerifying(false)
    },
    [gameId, tracker]
  )

  const handleDenyBingoClick = useCallback(async () => {
    setPlayerId('')
    setShowManualBingoVerificationModal(false)

    await apiClient.denyBingo(gameId, playerId)
    tracker.denyBingo(false, true)

    setIsVerifying(false)
  }, [gameId, playerId, tracker])

  const handleAdmitPlayerClick = useCallback(
    async (playerId: string) => {
      try {
        await apiClient.admitPlayers(gameId, [playerId])
        tracker.admitPlayer(playerId)
      } catch (error) {
        setErrorDialog({ error: getErrorMessage(error) })
        Sentry.captureException(error)
      }
    },
    [gameId, tracker, setErrorDialog]
  )

  const handleAdmitAllPlayersClick = useCallback(
    async (playerCount: number) => {
      try {
        await apiClient.admitAllPlayers(gameId)
        tracker.admitAllPlayers(playerCount)
      } catch (error) {
        setErrorDialog({ error: getErrorMessage(error) })
        Sentry.captureException(error)
      }
    },
    [gameId, tracker, setErrorDialog]
  )

  const handleRemovePlayerClick = useCallback(
    async (playerId: string) => {
      setConfirmPrompt({
        description:
          'The player will not be able to return to this game (unless you readmit them).',
        heading: 'Are you sure you want to remove this player from the game?',
        onConfirm: async () => {
          await apiClient.removePlayers(gameId, [playerId])
          tracker.removePlayer(playerId)
        }
      })
    },
    [gameId, tracker, setConfirmPrompt]
  )

  const handleReadmitPlayerClick = useCallback(
    (playerId: string) => {
      setConfirmPrompt({
        heading: 'Are you sure you want to readmit this player to the game?',
        onConfirm: async () => {
          await apiClient.readmitPlayers(gameId, [playerId])
          tracker.readmitPlayer(playerId)
        }
      })
    },
    [gameId, tracker, setConfirmPrompt]
  )

  const contextValue: GameControlsContext = {
    handleAdmitAllPlayersClick,
    handleAdmitPlayerClick,
    handleFinishGameClick,
    handleReadmitPlayerClick,
    handleRemovePlayerClick,
    handleStartGameClick,
    handleVerifyBingoClick,
    isFinishingGame,
    isStartingGame,
    isVerifying
  }

  return (
    <GameControlsContext.Provider value={contextValue}>
      {playerId && (
        <ManualBingoVerificationModal
          gameData={gameData}
          isVisible={showManualBingoVerificationModal}
          playerId={playerId}
          onConfirm={handleConfirmBingoClick}
          onDeny={handleDenyBingoClick}
        />
      )}

      {children}
    </GameControlsContext.Provider>
  )
})

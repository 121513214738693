import { parseStripeRole } from '@repo/lib'
import { Collections, User } from '@repo/types'
import { Auth, User as FirebaseUser, getAuth } from 'firebase/auth'
import { doc, getDoc } from 'firebase/firestore'
import { z } from 'zod'

import { getProSubscription } from '@/billing/getProSubscription'
import { getSubscriptions } from '@/billing/getSubscription'
import { databaseRef, firebaseApp } from '@/firebase/firebase'

import { getSignInProvider } from '../utils/get-signin-provider'

const PRO_STRIPE_ROLE = 'pro'

const TokenSchema = z.object({
  claims: z.object({
    inherit: z.array(z.literal('playlists')).default(() => []),
    name: z
      .string()
      .optional()
      .default(() => ''),
    parentId: z.string().optional(),
    stripeRole: z
      .string()
      .nullable()
      .catch(() => null)
  }),
  signInProvider: z.string()
})

type Token = z.infer<typeof TokenSchema>

async function parseToken(auth: Auth) {
  const decodedToken = await auth.currentUser?.getIdTokenResult(true)
  return TokenSchema.parse(decodedToken)
}

async function getSubscriptionData(userId: string, role: string | null) {
  const userDoc = await getDoc(doc(databaseRef, Collections.USERS, userId))

  const subscriptions = await getSubscriptions(userId)

  const hasAccount = userDoc.exists()

  const { maxPlayers, planType } = await parseStripeRole(userId, role, getProSubscription)

  return { hasAccount, maxPlayers, planType, subscriptions }
}

function parseSourceUser(token: Token, user: FirebaseUser) {
  const { parentId, stripeRole } = token.claims

  const hostIdForRestrictions = parentId || user.uid

  const role = (parentId ? PRO_STRIPE_ROLE : undefined) ?? stripeRole

  return {
    hostIdForRestrictions,
    role
  }
}

async function getAccountData(token: Token, user: FirebaseUser) {
  const { hostIdForRestrictions, role } = parseSourceUser(token, user)

  return getSubscriptionData(hostIdForRestrictions, role)
}

export async function transformUser(user: FirebaseUser): Promise<User> {
  const auth = getAuth(firebaseApp)

  const token = await parseToken(auth)

  const accountData = await getAccountData(token, user)

  const signInProvider = getSignInProvider(token.signInProvider)

  const transformedUser = {
    ...accountData,
    email: user.email || '',
    inherit: token.claims.inherit,
    isAnonymous: user.isAnonymous,
    name: token.claims.name,
    parentId: token.claims.parentId,

    signInProvider,
    userId: user.uid
  }

  return transformedUser
}

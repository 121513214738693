import { useEffect, useState } from 'react'

import { apiClient } from '@/api/api-client'
import { Button } from '@/components/Button'
import { useDialogContext } from '@/dialogs/DialogContext'
import { getErrorMessage } from '@/utils/get-error-message'

export function ClonePlaylistsButton({ isLoading }: { isLoading: boolean }) {
  const { setErrorDialog } = useDialogContext()
  const [isLoadingLocal, setIsLoadingLocal] = useState(isLoading)

  const handleClick = async () => {
    try {
      setIsLoadingLocal(true)
      await apiClient.clonePlaylists()
    } catch (error) {
      try {
        const errorMessage = JSON.parse(getErrorMessage(error))
        setErrorDialog({ error: errorMessage.error })
      } catch {
        setErrorDialog({ error: getErrorMessage(error) })
      } finally {
        setIsLoadingLocal(false)
      }
    }
  }

  useEffect(() => {
    setIsLoadingLocal(isLoading)
  }, [isLoading])

  return (
    <Button
      disabled={isLoading || isLoadingLocal}
      isLoading={isLoading || isLoadingLocal}
      loadingText='Cloning Playlists...'
      text='Clone Playlists'
      onClick={handleClick}
    />
  )
}

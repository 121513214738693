import { PLANTYPES, PlanSelection } from '@repo/types'
import { Spinner, Container } from '@repo/ui'
import * as Sentry from '@sentry/react'
import { observer } from 'mobx-react-lite'
import React, { useEffect, useState } from 'react'

import { LogoHeader } from '@/components/LogoHeader'
import { Main } from '@/components/Main'
import { Page } from '@/components/Page'
import { SubscriptionDetails } from '@/components/SubscriptionDetails'
import { TermsOfService } from '@/components/TermsOfService'
import { getErrorMessage } from '@/utils/get-error-message'

import {
  ERROR_EXISTING_SUBSCRIPTION,
  createCheckoutSession,
} from '../../billing'

const environment: 'local' | 'staging' | 'production' = import.meta.env.VITE_ENV

interface RedirectCheckoutProps {
  planSelection: PlanSelection
}

function redirectToCheckout({ interval, planType, quantity }: PlanSelection) {
  if (planType === 'free' || !interval) return

  const priceId = PLANTYPES[planType][interval].priceId[environment]

  return createCheckoutSession(priceId, planType, quantity)
}

export const RedirectCheckout = observer(function RedirectCheckout({
  planSelection,
}: RedirectCheckoutProps): React.ReactElement | null {
  const [hasSubscription, setHasSubscription] = useState(false)

  useEffect(() => {
    const redirect = async () => {
      try {
        await redirectToCheckout(planSelection)
      } catch (error) {
        if (getErrorMessage(error) === ERROR_EXISTING_SUBSCRIPTION) {
          setHasSubscription(true)
        } else {
          console.error(error)
          Sentry.captureException(error)
        }
      }
    }

    void redirect()
  }, [planSelection])

  if (hasSubscription) {
    return <SubscriptionDetails />
  }

  return (
    <Page title='Redirecting to Checkout'>
      <Main>
        <LogoHeader />

        <Container className='p-6' size='small'>
          <Spinner label='Loading checkout...' />
        </Container>

        <TermsOfService />
      </Main>
    </Page>
  )
})

import { Collections } from '@repo/types'
import {
  CheckCircleIcon,
  Container,
  Label,
  List,
  Panel,
  Spinner,
} from '@repo/ui'
import {
  DocumentData,
  QueryDocumentSnapshot,
  collection,
  limit,
  onSnapshot,
  orderBy,
  query,
} from 'firebase/firestore'
import { useEffect, useRef, useState } from 'react'
import { z } from 'zod'

import { LogoHeader } from '@/components/LogoHeader'
import { Main } from '@/components/Main'
import { Page } from '@/components/Page'
import { databaseRef } from '@/firebase/firebase'
import StoreService from '@/store/StoreService'

import { ClonePlaylistsButton } from './ClonePlaylistsButton'

export function ClonePlaylistsRoute() {
  return (
    <Page title='Clone Playlists'>
      <Main>
        <LogoHeader />

        <Container size='medium'>
          <ClonePlaylistsPage />
        </Container>
      </Main>
    </Page>
  )
}

const ClonePlaylistDataSchema = z.object({
  status: z.enum(['in_progress', 'complete', 'error']),
  transfers: z
    .record(
      z.string(),
      z.object({
        email: z.string(),
        size: z.number().optional(),
        status: z.enum(['in_progress', 'complete', 'error']),
      })
    )
    .optional(),
})

const converter = {
  fromFirestore: (snapshot: QueryDocumentSnapshot<DocumentData>) => {
    const data = snapshot.data()
    return ClonePlaylistDataSchema.parse(data)
  },
  toFirestore: (data: ClonePlaylistData) => data,
}

type ClonePlaylistData = z.infer<typeof ClonePlaylistDataSchema>

function ClonePlaylistsPage() {
  const isMounted = useRef(false)

  const store = StoreService.getStore()
  const { userId } = store.user

  const [isLoading, setIsLoading] = useState(true)
  const [data, setData] = useState<ClonePlaylistData | undefined>()

  useEffect(() => {
    if (isMounted.current) return

    isMounted.current = true

    onSnapshot(
      query(
        collection(
          databaseRef,
          Collections.USERS,
          userId,
          'playlist_clone_operations'
        ).withConverter(converter),
        orderBy('startTime', 'desc'),
        limit(1)
      ),
      (snapshot) => {
        if (snapshot.empty) {
          setIsLoading(false)
          return
        }

        const [doc] = snapshot.docs
        const data = doc.data()
        setData(data)

        setIsLoading(false)
      }
    )
  }, [userId])

  if (isLoading) {
    return <Spinner label='Loading...' />
  }

  return (
    <div className='flex flex-col gap-8'>
      <Panel>
        <div className='flex flex-col gap-6'>
          <div>
            <Label>Source user:</Label>
            {store.user.email}
          </div>
        </div>
      </Panel>

      <ClonePlaylistsButton isLoading={data?.status === 'in_progress'} />

      {data && <StatusDisplay data={data} />}
    </div>
  )
}

function StatusDisplay({ data }: { data: ClonePlaylistData }) {
  if (data.status === 'error') {
    return null
  }

  if (!data.transfers) return null

  return (
    <div className='flex flex-col gap-4'>
      <Label>Target Users:</Label>
      <List>
        {Object.entries(data.transfers)
          .sort((a, b) => a[1].email.localeCompare(b[1].email))
          .map(([key, { email, status }]) => (
            <List.Item key={key}>
              <div className='flex items-center gap-4'>
                <div className='w-6'>
                  {status === 'complete' && (
                    <CheckCircleIcon className='size-6' />
                  )}

                  {status === 'in_progress' && <Spinner className='size-6' />}
                </div>
                <div className='flex items-center'>{email}</div>
              </div>
            </List.Item>
          ))}
      </List>
    </div>
  )
}

import { Playlist } from '@repo/types'
import * as Sentry from '@sentry/react'
import { useQuery } from 'react-query'

import StoreService from '@/store/StoreService'
import { toErrorWithMessage } from '@/utils/get-error-message'

export function useQueryUserPlaylist(playlistId: string) {
  const { playlistService } = StoreService.getStore()

  const { queryFn, queryKey } = playlistService.queries.userPlaylist

  return useQuery<Playlist, Error>(
    queryKey(playlistId),
    () => queryFn(playlistId),
    {
      onError: (caughtError) => {
        const error = toErrorWithMessage(caughtError)
        console.error(error)
        Sentry.captureException(error)
        return error
      },
    }
  )
}
